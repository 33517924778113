import React, { useContext, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import DateInput from "components/Inputs/DateInput";
import { GlobalContext } from "contexts/Global";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Switch from "components/Switch";
import Icon from "components/Icon";
import CustomTimePicker from "components/CustomTimePicker";
import DaySelector from "components/DaySelector";
import NumberInput from "components/NumberInput";
import { useUpdateScheduleTickerTape } from "../../graphql/useTickerTapes";
import { toast } from "react-toastify";
import { TICKER_TAPE_TYPE } from "../../constants";
import {
    TimePickerComponent,
    formatDateYYYYMMDD,
    convertInitialTimeRanges,
    validateRanges,
    convertTimeRanges,
    formatDate,
} from "../../utils";
import { useUpdateScheduleBanner } from "../../graphql/useBanners";

const ModalScheduleTickerTapeBanner = (props) => {
    const { t } = useTranslation();
    const { data, close, onCompleted } = props;

    const {
        modalTitle,
        id,
        scheduleDaysOfWeek,
        scheduleDaysType,
        scheduleStart,
        scheduleEnd,
        scheduleFrequencyDisplay,
        scheduleFrequencyLimited,
        timeRanges,
        scheduleObtrusiveMode,
        scheduleFrequencyRepetitions,
        type,
        scheduleDuration,
    } = data;

    const [dataSchedule, setDataSchedule] = useState({
        scheduleStart: scheduleStart ? formatDate(scheduleStart) : null,
        scheduleEnd: scheduleEnd ? formatDate(scheduleEnd) : null,
        scheduleDaysType: scheduleDaysType === "SPECIFIC_DAYS" ? "SPECIFIC_DAYS" : "ALL_DAYS",
        scheduleObtrusiveMode: scheduleObtrusiveMode || false,
        timeRanges: timeRanges ? convertInitialTimeRanges(timeRanges) : [{ startTime: null, endTime: null }],
        scheduleFrequencyLimited: scheduleFrequencyLimited || false,
        scheduleFrequencyDisplay: scheduleFrequencyDisplay || null,
        scheduleDaysOfWeek: scheduleDaysOfWeek || [],
        scheduleFrequencyRepetitions: scheduleFrequencyRepetitions || 1,
        scheduleDuration: scheduleDuration || null,
    });

    const {
        lang,
        selectedDays,
        modifiers,
        updateScheduleStartEnd,
        updateScheduleDaysType,
        updateScheduleItem,
        deleteScheduleItem,
        addRange,
        validateRequiredFields,
        requiredFields,
    } = useScheduleTickerTapeBannerState(dataSchedule, setDataSchedule);

    const { updateScheduleTickerTape, loadingScheduleTickerTape } = useUpdateScheduleTickerTape({
        onCompleted: () => {
            onCompleted();
            close();
        },
    });

    const { updateScheduleBanner, loadingScheduleBanner } = useUpdateScheduleBanner({
        onCompleted: () => {
            onCompleted();
            close();
        },
    });

    const updateAction = type === TICKER_TAPE_TYPE ? updateScheduleTickerTape : updateScheduleBanner;

    return (
        <Modal
            title={t(modalTitle)}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-save"
                        onClick={() => {
                            if (!validateRequiredFields()) {
                                toast.error(t("input error"));
                            } else {
                                updateAction({
                                    variables: {
                                        id,
                                        scheduleStart: dataSchedule.scheduleStart
                                            ? formatDateYYYYMMDD(dataSchedule.scheduleStart)
                                            : null,
                                        scheduleEnd: dataSchedule.scheduleEnd
                                            ? formatDateYYYYMMDD(dataSchedule.scheduleEnd)
                                            : "<null>",
                                        timeRanges: convertTimeRanges(dataSchedule.timeRanges),
                                        scheduleObtrusiveMode: dataSchedule.scheduleObtrusiveMode,
                                        scheduleFrequencyLimited: dataSchedule.scheduleFrequencyLimited,
                                        scheduleFrequencyDisplay: dataSchedule.scheduleFrequencyLimited
                                            ? dataSchedule.scheduleFrequencyDisplay
                                            : "00:00:00",
                                        scheduleDaysOfWeek: dataSchedule.scheduleDaysOfWeek,
                                        scheduleFrequencyRepetitions: dataSchedule.scheduleFrequencyLimited
                                            ? dataSchedule.scheduleFrequencyRepetitions || 1
                                            : 1,
                                        scheduleDaysType: dataSchedule.scheduleDaysType,
                                        scheduleDuration: dataSchedule.scheduleDuration,
                                    },
                                });
                            }
                        }}
                        disabled={loadingScheduleTickerTape || loadingScheduleBanner}
                    >
                        {t("save")}
                    </Button>
                </>
            }
            className="w-9/12 p-10"
        >
            {loadingScheduleTickerTape || loadingScheduleBanner ? (
                <Loading />
            ) : (
                <div className="flex w-full">
                    <div className="w-1/2">
                        <div>
                            <p>
                                {t(
                                    `schedule-the-${
                                        type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"
                                    }-will-be-displayed-on-the-tv-interface`
                                )}
                            </p>
                            <div className="flex mt-5">
                                <div className="mr-12">
                                    <label className="font-bold text-gray-800 mb-3 block">{t("start-date")}*</label>
                                    <DateInput
                                        id="start-date-input"
                                        value={dataSchedule.scheduleStart}
                                        onChange={(date) => updateScheduleStartEnd("scheduleStart", date)}
                                        placeholder={lang === "en" ? "mm/dd/yyyy" : "dd/mm/yyyy"}
                                        disabledDays={
                                            dataSchedule.scheduleEnd ? { after: dataSchedule.scheduleEnd } : undefined
                                        }
                                        selectedDays={selectedDays}
                                        required={requiredFields.scheduleStart}
                                        modifiers={modifiers}
                                    />
                                </div>
                                <div>
                                    <label className="font-bold text-gray-800 mb-3 block">{t("end-date")}</label>
                                    <DateInput
                                        id="end-date-input"
                                        value={dataSchedule.scheduleEnd}
                                        onChange={(date) => updateScheduleStartEnd("scheduleEnd", date)}
                                        placeholder={lang === "en" ? "mm/dd/yyyy" : "dd/mm/yyyy"}
                                        disabledDays={
                                            dataSchedule.scheduleStart
                                                ? { before: dataSchedule.scheduleStart }
                                                : undefined
                                        }
                                        selectedDays={selectedDays}
                                        modifiers={modifiers}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className="block mt-4 mb-4">
                                {t(
                                    `select-the-days-to-display-${type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"}`
                                )}
                            </label>
                            <Radiobutton
                                id="radio-all-days"
                                value="ALL_DAYS"
                                label={t("all-days-of-the-week")}
                                checked={dataSchedule.scheduleDaysType === "ALL_DAYS"}
                                onChange={({ value }) => updateScheduleDaysType(value)}
                            />
                            <Radiobutton
                                className="mt-3"
                                id="radio-weekdays"
                                value="SPECIFIC_DAYS"
                                checked={dataSchedule.scheduleDaysType === "SPECIFIC_DAYS"}
                                label={t("filter-by-days-of-week")}
                                onChange={({ value }) => updateScheduleDaysType(value)}
                            />
                            {dataSchedule.scheduleDaysType === "SPECIFIC_DAYS" && (
                                <div className="w-1/3 mt-3 ml-8">
                                    <DaySelector
                                        multiple={true}
                                        language={lang}
                                        onChange={(days) => {
                                            setDataSchedule({
                                                ...dataSchedule,
                                                scheduleDaysOfWeek: days,
                                            });
                                        }}
                                        defaultValue={dataSchedule.scheduleDaysOfWeek}
                                        isValid={!requiredFields.scheduleDaysOfWeek}
                                        id="day-selector"
                                    />
                                    {requiredFields.scheduleDaysOfWeek && (
                                        <span className="text-red-100">{t("select-at-least-a-weekday")}</span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex mt-5">
                            <div className="mr-3">{t("obstrusive-mode")}</div>
                            <Switch
                                id="switch-obstrusive-mode"
                                checked={dataSchedule.scheduleObtrusiveMode}
                                onChange={({ checked }) => {
                                    setDataSchedule({
                                        ...dataSchedule,
                                        scheduleObtrusiveMode: checked,
                                    });
                                }}
                            />
                            <Icon
                                tooltip={t(
                                    `the-${
                                        type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"
                                    }-will-be-displayed-on-the-tv-while-the-guest-is-watching-a-full-screen-video`
                                )}
                                type="info"
                                size={1.5}
                                className="ml-3"
                            />
                        </div>
                    </div>

                    <div className="w-1/2">
                        <div className="font-bold mb-2">{t("daily-schedule")}</div>
                        <div className="mb-3">
                            {t(
                                `select-the-daily-time-range-of-${type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"}`
                            )}
                        </div>
                        <div>
                            {dataSchedule.timeRanges.map((timeRange, index) => (
                                <TimePickerComponent
                                    key={index}
                                    index={index}
                                    startTime={timeRange.startTime}
                                    endTime={timeRange.endTime}
                                    isValid={requiredFields.timeRanges?.[index]}
                                    updateScheduleItem={updateScheduleItem}
                                    deleteScheduleItem={deleteScheduleItem}
                                />
                            ))}
                            {dataSchedule.timeRanges.length < 4 && (
                                <Button
                                    onClick={addRange}
                                    id="add-range-time"
                                    className="text-zafiro-600 ml-6 mb-6 mt-3"
                                >
                                    <Icon type="add" />
                                    <span className="font-bold">{t("add-range")}</span>
                                </Button>
                            )}
                        </div>

                        <div className="flex flex-col">
                            <div className="flex">
                                <div className="mr-3 font-bold">{t("frecuency")}</div>
                                <Icon
                                    tooltip={t(
                                        `the-${
                                            type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"
                                        }-will-be-displayed-in-a-loop-within-the-selected-time-range`
                                    )}
                                    type="info"
                                    size={1.5}
                                    className="ml-3"
                                />
                            </div>
                            <div className="flex  mt-3">
                                <span>{t("limit-the-frequency")}</span>

                                <Switch
                                    id="limit-the-frequency"
                                    className="ml-2"
                                    checked={dataSchedule.scheduleFrequencyLimited}
                                    onChange={({ checked }) => {
                                        setDataSchedule({
                                            ...dataSchedule,
                                            scheduleFrequencyLimited: checked,
                                        });
                                    }}
                                />
                            </div>
                            {dataSchedule.scheduleFrequencyLimited && (
                                <div className=" mt-2">
                                    {t(
                                        `set-how-often-the-${
                                            type === TICKER_TAPE_TYPE ? "ticker-tape" : "banner"
                                        }-will-be-displayed-on-screen`
                                    )}
                                </div>
                            )}
                            {dataSchedule.scheduleFrequencyLimited && (
                                <div className="w-full flex items-center mt-3">
                                    <div>{t("display-frequency")}*</div>
                                    <div className="w-1/6 ml-4">
                                        <CustomTimePicker
                                            value={dataSchedule.scheduleFrequencyDisplay}
                                            onChange={(value) => {
                                                setDataSchedule({
                                                    ...dataSchedule,
                                                    scheduleFrequencyDisplay: value,
                                                });
                                            }}
                                            error={requiredFields.scheduleFrequencyLimited}
                                            id="schedule-frequency"
                                            units={["hours", "minutes", "seconds"]}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {type !== TICKER_TAPE_TYPE && (
                            <div className="flex flex-col mt-5">
                                <div className="flex">
                                    <div className="mr-3 font-bold">{t("duration")}</div>
                                    <Icon
                                        type="info"
                                        size={1.5}
                                        tooltip={t(
                                            "if-there-are-not-duration-the-banner-will-be-displayed-until-the-guest-closes-it"
                                        )}
                                        className="ml-3"
                                    />
                                </div>
                                <div className="flex mt-3">
                                    {t("set-the-time-the-banner-will-remain-visible-before-closing")}
                                </div>
                                <div className="w-full flex items-center mt-3">
                                    <div>{t("banner-duration")}</div>
                                    <div className="w-1/6 ml-4">
                                        <CustomTimePicker
                                            onChange={(value) => {
                                                setDataSchedule({
                                                    ...dataSchedule,
                                                    scheduleDuration: value,
                                                });
                                            }}
                                            value={dataSchedule.scheduleDuration}
                                            id="schedule-duration"
                                            units={["minutes", "seconds"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {type === TICKER_TAPE_TYPE && dataSchedule.scheduleFrequencyLimited && (
                            <div className="flex flex-col mt-5">
                                <div className="flex mt-3">
                                    {t("set-the-number-of-times-the-text-should-repeat-in-a-row")}
                                </div>
                                <div className="w-full flex items-center mt-3">
                                    <div>{t("repetitions")}</div>
                                    <div className="w-1/6 ml-4 ">
                                        <NumberInput
                                            min={1}
                                            id="schedule-repetition"
                                            value={dataSchedule.scheduleFrequencyRepetitions}
                                            className="w-5/6"
                                            onChange={(value) => {
                                                setDataSchedule({
                                                    ...dataSchedule,
                                                    scheduleFrequencyRepetitions: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>{t("times")}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
};

function useScheduleTickerTapeBannerState(dataSchedule, setDataSchedule) {
    const { lang } = useContext(GlobalContext);

    const [requiredFields, setRequiredFields] = useState({
        scheduleStart: false,
        timeRanges: [],
        scheduleDaysOfWeek: false,
    });

    const selectedDays =
        dataSchedule.scheduleStart && dataSchedule.scheduleEnd
            ? { from: dataSchedule.scheduleStart, to: dataSchedule.scheduleEnd }
            : dataSchedule.scheduleStart;

    const modifiers = {
        start: dataSchedule.scheduleStart,
        end: dataSchedule.scheduleEnd,
    };

    const updateScheduleStartEnd = (typeDate, date) => {
        setDataSchedule((prev) => ({
            ...prev,
            [typeDate]: date,
        }));
    };

    const updateScheduleDaysType = (value) => {
        setDataSchedule((prev) => ({
            ...prev,
            scheduleDaysType: value,
        }));
    };

    const updateScheduleItem = (index, field, value) => {
        setDataSchedule((prevState) => {
            const newTimeRanges = [...prevState.timeRanges];
            newTimeRanges[index] = { ...newTimeRanges[index], [field]: value };
            return { ...prevState, timeRanges: newTimeRanges };
        });
    };

    const deleteScheduleItem = (index) => {
        setDataSchedule((prevState) => {
            const newTimeRanges = prevState.timeRanges.filter((_, i) => i !== index);
            return { ...prevState, timeRanges: newTimeRanges };
        });
    };

    const addRange = () => {
        setDataSchedule((prevState) => ({
            ...prevState,
            timeRanges: [...prevState.timeRanges, { startTime: null, endTime: null }],
        }));
    };

    const validateRequiredFields = () => {
        const timeRangesValidation = validateRanges(dataSchedule.timeRanges);

        const newRequiredFields = {
            scheduleStart: !dataSchedule.scheduleStart,
            timeRanges: timeRangesValidation,
            scheduleDaysOfWeek:
                dataSchedule.scheduleDaysType === "SPECIFIC_DAYS" && dataSchedule.scheduleDaysOfWeek.length === 0,
            scheduleFrequencyLimited:
                dataSchedule.scheduleFrequencyLimited &&
                (!dataSchedule.scheduleFrequencyDisplay || dataSchedule.scheduleFrequencyDisplay === "00:00:00"),
        };

        setRequiredFields(newRequiredFields);

        const hasTimeRangeErrors = !Object.values(timeRangesValidation).every((range) => range.start && range.end);

        return (
            !newRequiredFields.scheduleStart &&
            !hasTimeRangeErrors &&
            !newRequiredFields.scheduleDaysOfWeek &&
            !newRequiredFields.scheduleFrequencyLimited
        );
    };

    return {
        lang,
        dataSchedule,
        setDataSchedule,
        requiredFields,
        setRequiredFields,
        selectedDays,
        modifiers,
        updateScheduleStartEnd,
        updateScheduleDaysType,
        updateScheduleItem,
        deleteScheduleItem,
        addRange,
        validateRequiredFields,
    };
}

export const useModalScheduleTickerTapeBanner = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalScheduleTickerTapeBanner {...newProps} />);
        },
    };
};

export default useModalScheduleTickerTapeBanner;
