import { gql } from "apollo-boost";

export const GET_SIGNAGES = gql`
    query listSignages(
        $scheduleFrom: String
        $scheduleTo: String
        $search: String
        $orderBy: OrderBy
        $id: Int64
        $available: [Boolean]
        $onlyOnGoing: Boolean
        $onlyExpired: Boolean
        $currentTime: DateTime
    ) {
        signages(
            filter: {
                search: $search
                scheduleFrom: $scheduleFrom
                scheduleTo: $scheduleTo
                id: $id
                available: $available
                onlyOngoing: $onlyOnGoing
                onlyExpired: $onlyExpired
            }
            orderBy: $orderBy
            currentDateTime: $currentTime
        ) {
            info {
                count
                page
                size
            }
            results {
                id
                filtersNum
                isDefault
                ongoing
                name
                available
                destinationID
                contentDefaultFilesDuration
                contentLanguageRef
                scheduleStart
                scheduleEnd
                scheduleDaysType
                scheduleDaysOfWeek
                scheduleDailyType
                destinationInfo {
                    roomTvsTpCommonZone {
                        id
                        name
                    }
                    roomGroupsTpCommonZone {
                        id
                        name
                    }
                    roomsTpCommonZone {
                        id
                        name
                    }
                }
                contents {
                    duration
                    id
                    pos
                    ref
                    signageID
                    type
                }
                timeRanges {
                    endTime
                    startTime
                }
            }
        }
        designsLocalAssigned: designs(filter: { isAssigned: true }) {
            results {
                id
            }
        }
        designs: designs {
            results {
                id
                isAssignedFromCorporate
                name
            }
        }
    }
`;

export const GET_FILTERS = gql`
    query getFilters {
        roomTvsTpGuestRoom: roomtvs(filter: { roomType: GUESTROOM }) {
            results {
                id
                name
            }
        }
        roomsTpGuestRoom: rooms(orderBy: { field: "name", criteria: "ASC" }, filter: { type: GUESTROOM }) {
            results {
                id
                name
                number
                roomTVs {
                    id
                    name
                }
            }
        }
        roomGroupsTpGuestRoom: roomGroups(filter: { type: GUESTROOM }) {
            results {
                id
                name
                rooms {
                    id
                    name
                    roomTVs {
                        id
                        name
                    }
                }
            }
        }
        wings: installationMap {
            results {
                id
                name
                floors {
                    id
                    name
                    rooms {
                        id
                        name
                        type
                    }
                }
            }
        }

        stayGuests: guests(
            mainGuest: true
            filter: { checkedOut: false }
            orderBy: { field: "name", criteria: "ASC" }
        ) {
            results {
                id
                name
                surname
                rooms {
                    roomID
                }
            }
        }

        stayGroups: guestGroups(orderBy: { field: "name", criteria: "ASC" }) {
            results {
                id
                name
            }
        }

        languages {
            results {
                ref
            }
        }

        countries {
            results {
                information {
                    name
                }
                ref
            }
        }

        roomsTpCommonZone: rooms(filter: { type: COMMONZONE }) {
            results {
                id
                name
                roomTVs {
                    id
                    name
                }
            }
        }
        roomGroupsTpCommonZone: roomGroups(filter: { type: COMMONZONE }) {
            results {
                id
                name
                rooms {
                    id
                    name
                    roomTVs {
                        id
                        name
                    }
                }
            }
        }
        roomTvsTpCommonZone: roomtvs(filter: { roomType: COMMONZONE }) {
            results {
                id
                name
            }
        }
    }
`;

export const GET_FILTER_DESTINATION = gql`
    query filterDestination($filters: destinationSET, $onlyWithGuest: Boolean) {
        filterDestination(destination: $filters, onlyRoomsWithGuests: $onlyWithGuest) {
            commonZoneReasons {
                reason {
                    all
                    countryRefs
                    floorIDs
                    languageRefs
                    roomGroupTpGuestRoomIDs
                    roomTpGuestRoomIDs
                    stayGroupIDs
                    stayGuestIDs
                    wingIDs
                }
                roomId
                roomName
            }
            roomTVReasons {
                reason {
                    all
                    countryRefs
                    floorIDs
                    languageRefs
                    roomGroupTpGuestRoomIDs
                    roomTpGuestRoomIDs
                    stayGroupIDs
                    stayGuestIDs
                    wingIDs
                }
                roomName
                roomTvId
                roomTvName
            }
            stayGuestRoomReasons {
                reason {
                    all
                    countryRefs
                    floorIDs
                    languageRefs
                    roomGroupTpGuestRoomIDs
                    roomTpGuestRoomIDs
                    stayGroupIDs
                    stayGuestIDs
                    wingIDs
                }
                roomId
                roomName
                stayGuestName
                stayGuestSurname
            }
        }
    }
`;

export const GET_ASSET = gql`
    query libraryAsset($ref: String!) {
        content: libraryAsset(ref: $ref) {
            error
            errorCode
            ok
            response {
                name
                contentType
                defaultLanguage
                size
                languages {
                    imageFormat
                    language
                    name
                    size
                    contentType
                    dimensions {
                        height
                        width
                    }
                }
            }
        }
    }
`;

export const GET_VIDEO = gql`
    query libraryVideo($ref: String!) {
        content: libraryVideo(ref: $ref) {
            error
            errorCode
            ok
            response {
                name
                ready
                contentType
                size
                defaultLanguage
                languages {
                    name
                    language
                    duration
                    resolution
                    size
                    streamStatus
                }
            }
        }
    }
`;

export const GET_SCREEN = gql`
    query getScreen($ref: Int64!, $designID: Int64!) {
        content: content(id: $ref, designID: $designID) {
            results {
                name
            }
        }
    }
`;

export const GET_LIBRARY_CONTENTS_AND_DESIGNS_LOCAL_ASSIGNED = gql`
    query getLibraryContentsAndDesignsLocalAssigned(
        $page: Int
        $size: Int
        $orderBy: OrderBy
        $filter: LibraryContentsFilter
        $ref: String
    ) {
        libraryContents(page: $page, size: $size, orderBy: $orderBy, filter: $filter, ref: $ref) {
            response {
                results {
                    items
                    lastModified
                    name
                    parentRef
                    path
                    ready
                    ref
                    size
                    type
                }
            }
        }
        designsLocalAssigned: designs(filter: { isAssigned: true }) {
            results {
                id
                isAssigned
                isAssignedFromCorporate
                lastUpdatedTime
                type
                themeID
                name
            }
        }
        designs: designs {
            results {
                id
                isAssignedFromCorporate
                name
            }
        }
    }
`;

export const GET_CONTENT_TREE = gql`
    query GetContentTree($designID: Int64!) {
        contentTree(designID: $designID) {
            screens {
                dir {
                    contents {
                        id
                        isEnabled
                        isPublished
                        isLinked
                        name
                        type
                        linkedFrom
                        linkedSignages {
                            id
                            name
                        }
                        linkedBanners {
                            id
                            name
                        }
                    }
                    subDirs {
                        id
                        name
                        contents {
                            id
                            isEnabled
                            isPublished
                            isLinked
                            name
                            type
                            linkedFrom
                            linkedSignages {
                                id
                                name
                            }
                            linkedBanners {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LIBRARY_VIDEO_INFO = gql`
    query GetLibraryVideoInfo($ref: String!) {
        libraryVideo(ref: $ref) {
            response {
                defaultLanguage
                languages {
                    language
                    duration
                }
            }
        }
    }
`;

export const GET_GUEST_GROUP = gql`
    query Guests($ggId: [Int64]!) {
        data: guests(filter: { checkedOut: false }, mainGuest: true, orderBy: { field: "name", criteria: "asc" }) {
            info {
                count
            }
            results {
                id
                name
                surname
                rooms {
                    name
                    number
                }
            }
        }
        selected: guests(filter: { guestGroup: $ggId }) {
            results {
                id
                __typename
            }
            __typename
        }
    }
`;

export const GENERATE_QUERY_LIBRARY_VIDEOS_INFO = (refs) => {
    const queryString =
        refs.length > 0
            ? refs
                  .map(
                      (ref, index) => `
                video${index}: libraryVideo(ref: "${ref}") {
                    response {
                    ref    
                    defaultLanguage
                        languages {
                            language
                            duration
                        }
                    }
                }
            `
                  )
                  .join("\n")
            : `fakeVideo: libraryVideo(ref: "fakeRef") {
                response {
                    defaultLanguage
                    languages {
                        language
                        duration
                    }
                }
            }`;

    return gql`
        query {
            ${queryString}
        }
    `;
};

export const GENERATE_QUERY_GET_DATA_CONTENTS = (items, designIdAssigned) => {
    const screenItems = items.filter((item) => item.type === "SCREEN");
    const libraryItems = items.filter((item) => item.type !== "SCREEN");

    const responseFields = `
        name
        size
        defaultLanguage
        languages {
            language
        }
    `;

    const libraryQueries = libraryItems.map((item) => {
        const alias = `libraryItem${item.id}`;
        const field = item.type === "ASSET" ? "libraryAsset" : "libraryVideo";

        return `${alias}: ${field}(ref: "${item.ref}") {
            response {
                ${responseFields}
            }
        }`;
    });

    const screenQueries = screenItems.map((item) => {
        const alias = `screenItem${item.ref}`;
        return `${alias}: content(id: ${item.ref}, designID: ${designIdAssigned}) {
            results {
                name
            }
        }`;
    });

    const finalQueries =
        libraryQueries.length > 0 || screenQueries.length > 0
            ? `query GetLibraryContentData {
                ${[...libraryQueries, ...screenQueries].join("\n")}
            }`
            : `query fakeQuery {
                fakeData
            }`;

    return gql`
        ${finalQueries}
    `;
};
