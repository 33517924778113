import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useListFilters, useGetFiltersDestinations } from "../graphql/useSignages";
import {
    CZ_TYPE,
    GROUP_CZ_TYPE,
    TV_CZ_LOCATION_TYPE,
    ROOM_TYPE,
    ROOM_GROUP_TYPE,
    FLOOR_TYPE,
    GUESTROOM,
    COMMONZONE,
    TV_ROOM_TYPE,
    WING_TYPE,
    STAY_GUEST_TYPE,
    STAY_GUEST_GROUP_TYPE,
    LANGUAGES_TYPE,
    COUNTRIES_TYPE,
} from "../constants";
import Loading from "components/Loading";

const ModalFilters = (props) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState();
    const [filters, setFilters] = useState([]);
    const [tvsResults, setTvsResults] = useState([]);
    const { getFilters, loading, filters: listFilters, destructFilters, done } = useListFilters();
    const {
        getFilterDestinations,
        destinations,
        loading: loadingDestinations,
        done: doneFilterDestination,
    } = useGetFiltersDestinations({ t });

    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        if (!selected && done) {
            const lf =
                Object.entries(props?.filters).reduce((acc, curr) => {
                    if (typeof curr[1] == "object" && curr[1]?.length > 0) {
                        acc = [
                            ...acc,
                            ...curr[1].map((val) => ({
                                name:
                                    curr[0] == TV_CZ_LOCATION_TYPE
                                        ? listFilters?.roomTvsTpCommonZone?.find((cz) => {
                                              return cz.id == val.id;
                                          })?.name
                                        : curr[0] == TV_ROOM_TYPE
                                        ? listFilters?.roomTvsTpGuestRoom?.find((room) => {
                                              return room.id == val.id;
                                          })?.name
                                        : curr[0] == LANGUAGES_TYPE
                                        ? t(`language:${val.ref}`)
                                        : curr[0] == COUNTRIES_TYPE
                                        ? t(`country:${val.ref}`)
                                        : val.name,
                                id: val.id || val.ref,
                                type: curr[0],
                                style:
                                    curr[0] == TV_CZ_LOCATION_TYPE || curr[0] == CZ_TYPE || curr[0] == GROUP_CZ_TYPE
                                        ? "bg-purple-700 text-white cursor-default"
                                        : "bg-blue-200 text-white cursor-default",
                            })),
                        ];
                    }
                    if (curr[0] == "all") {
                        acc = [
                            ...acc,
                            {
                                name: t("all property"),
                                id: "all",
                                type: "all",
                                style: "bg-blue-200 text-white cursor-default",
                            },
                        ];
                    }
                    return acc;
                }, []) || [];
            setFilters(lf);
            setSelected(lf?.[0]);
        }
    }, [listFilters]);

    useEffect(() => {
        if (selected?.id) {
            const { roomsTpGuestRoom, roomGroupsTpGuestRoom, roomsTpCommonZone, roomGroupsTpCommonZone, wings } =
                destructFilters;
            switch (selected?.type) {
                case CZ_TYPE:
                    const cz = roomsTpCommonZone?.results?.find((room) => room?.id == selected?.id);
                    setTvsResults(cz.roomTVs.map((roomtv) => ({ ...roomtv, name: `${cz.name} - ${roomtv.name}` })));
                    break;
                case GROUP_CZ_TYPE:
                    const czGroup = roomGroupsTpCommonZone?.results
                        ?.find((roomGroup) => roomGroup.id == selected?.id)
                        ?.rooms?.reduce((acc, room) => {
                            acc = [
                                ...acc,
                                ...(room?.roomTVs?.map((roomtv) => ({
                                    ...roomtv,
                                    name: `${room.name} - ${roomtv.name}`,
                                })) || []),
                            ];
                            return acc;
                        }, []);
                    setTvsResults(czGroup);
                    break;
                case TV_CZ_LOCATION_TYPE:
                    setTvsResults([selected]);
                    break;
                case ROOM_TYPE:
                    const room = roomsTpGuestRoom?.results?.find((room) => room?.id == selected?.id);
                    setTvsResults(room.roomTVs.map((roomtv) => ({ ...roomtv, name: `${room.name} - ${roomtv.name}` })));
                    break;
                case ROOM_GROUP_TYPE:
                    const roomGroup = roomGroupsTpGuestRoom?.results
                        ?.find((roomGroup) => roomGroup.id == selected?.id)
                        .rooms?.reduce((acc, room) => {
                            acc = [
                                ...acc,
                                ...(room?.roomTVs?.map((roomtv) => ({
                                    ...roomtv,
                                    name: `${room.name} - ${roomtv.name}`,
                                })) || []),
                            ];
                            return acc;
                        }, []);
                    setTvsResults(roomGroup);
                    break;
                case TV_ROOM_TYPE:
                    setTvsResults([selected]);
                    break;
                case FLOOR_TYPE:
                    const floor = wings?.results
                        ?.find((wing) => wing.floors.find((floor) => floor.id == selected.id))
                        ?.floors?.find((floor) => floor.id == selected.id);
                    const tvs = floor?.rooms?.reduce((acc, curr) => {
                        let room;
                        if (curr.type == GUESTROOM) {
                            room = roomsTpGuestRoom?.results?.find((room) => room.id == curr.id);
                        } else if (curr.type == COMMONZONE) {
                            room = roomsTpCommonZone?.results?.find((room) => room.id == curr.id);
                        }
                        acc = [
                            ...acc,
                            ...room.roomTVs.map((roomtv) => ({ ...roomtv, name: `${room.name} - ${roomtv.name}` })),
                        ];
                        return acc;
                    }, []);
                    setTvsResults(tvs);
                    break;
                case WING_TYPE:
                    const wing = wings?.results?.find((wing) => wing.id == selected.id);
                    const wingTvs = wing?.floors?.reduce((acc, curr) => {
                        const tvs = curr?.rooms?.reduce((acc, curr) => {
                            let room;
                            if (curr.type == GUESTROOM) {
                                room = roomsTpGuestRoom?.results?.find((room) => room.id == curr.id);
                            } else if (curr.type == COMMONZONE) {
                                room = roomsTpCommonZone?.results?.find((room) => room.id == curr.id);
                            }
                            acc = [
                                ...acc,
                                ...room.roomTVs.map((roomtv) => ({ ...roomtv, name: `${room.name} - ${roomtv.name}` })),
                            ];
                            return acc;
                        }, []);
                        acc = [...acc, ...tvs];
                        return acc;
                    }, []);

                    setTvsResults(wingTvs);
                    break;
                case STAY_GUEST_TYPE:
                    getFilterDestinations({ variables: { filters: { stayGuestIDs: [selected.id] } }, listFilters });
                    break;
                case STAY_GUEST_GROUP_TYPE:
                    getFilterDestinations({ variables: { filters: { stayGroupIDs: [selected.id] } }, listFilters });
                    break;
                case LANGUAGES_TYPE:
                    getFilterDestinations({ variables: { filters: { languageRefs: [selected.id] } }, listFilters });
                    break;
                case COUNTRIES_TYPE:
                    getFilterDestinations({ variables: { filters: { countryRefs: [selected.id] } }, listFilters });
                    break;
                case "all":
                    setTvsResults([
                        ...(listFilters?.roomTvsTpCommonZone || []),
                        ...(listFilters?.roomTvsTpGuestRoom || []),
                    ]);
                    break;
            }
        }
    }, [destructFilters, selected]);

    useEffect(() => {
        if (selected?.id && doneFilterDestination) {
            switch (selected?.type) {
                case STAY_GUEST_TYPE:
                case STAY_GUEST_GROUP_TYPE:
                case LANGUAGES_TYPE:
                case COUNTRIES_TYPE:
                    const ids = destinations?.rooms?.chips
                        ?.map((room) => room.id)
                        .reduce((acc, curr) => {
                            const room =
                                destructFilters?.roomsTpGuestRoom?.results.find((room) => room.id == curr) || [];
                            const tvs =
                                room?.roomTVs?.map((roomtv) => ({
                                    ...roomtv,
                                    name: `${room.name} - ${roomtv.name}`,
                                })) || [];
                            acc = [...acc, ...tvs];
                            return acc;
                        }, []);
                    setTvsResults(ids);
                    break;
            }
        }
    }, [doneFilterDestination]);

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("filters")}`}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-8/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" grid grid-cols-5 gap-2">
                    <div className=" col-span-3">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t(`${props.type}-has-the-following-filters`, {
                                    name: `<strong>${props.name}</strong>`,
                                }),
                            }}
                        ></p>
                        <p className="mt-4 font-bold">
                            {t("filters")}
                            <span className=" font-normal pl-2">({t("filters-x", { count: filters.length })})</span>
                        </p>
                        <div className=" mt-2 flex gap-2 flex-wrap">
                            {filters?.map((val) => {
                                return (
                                    <p
                                        key={val.name + "-" + val.id}
                                        className={`${
                                            val?.id == selected?.id ? selected?.style : "bg-gray-300 cursor-pointer"
                                        } py-1 px-2 w-fit-content rounded-md`}
                                        onClick={() => {
                                            if (val.id != selected?.id) {
                                                setSelected(val);
                                            }
                                        }}
                                    >
                                        {val.name}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className=" col-span-2">
                        <div className=" rounded-md flex justify-between mb-2">
                            <span>{t("results-of-the-selected-filter")}:</span>
                            <span className="text-gray-500 ">{t("x rows_other", { count: tvsResults?.length })}</span>
                        </div>
                        <div className="flex flex-col border p-2 rounded-md border-gray-500 min-h-40 max-h-40 overflow-y-auto">
                            {loadingDestinations ? (
                                <div className="w-full h-full flex-1 flex justify-center items-center">
                                    <Loading></Loading>
                                </div>
                            ) : (
                                <>
                                    {tvsResults?.length > 0 ? (
                                        tvsResults?.map((val) => (
                                            <p className="px-2 py-3 mb-2 border-b border-gray-500">{val.name}</p>
                                        ))
                                    ) : (
                                        <div className=" flex flex-1 justify-center items-center">
                                            <p className=" text-gray-800">{t("no-tv-locations-with-this-filter")}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalFilters {...newProps} />);
        },
    };
};
