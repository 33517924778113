import React, { useState, useEffect } from "react";
import classNames from "classnames";

import ErrorInfo from "components/ErrorInfo";
import Table from "components/ZafiroTable";
import Tag from "components/Tag";
import Icon from "components/Icon";
import Button from "components/Button";

import { Header } from "sections/playground";

const API_URL = "https://gitlab.zafiro.link/api/v4";
const PROJECT_QA = 88;
const TOKEN = "glpat-qcwzyE1MWNCMjJWAsSN4";

const LOCALE = "es-ES";

const CriticalPriority = "1 - Critical priority";
const HighPriority = "2+ High priority";
const NormalPriority = "2 - Normal priority";
const MinorPriority = "3 - Minor priority";
const Dismissed = "4 - Very Low(dismissed)";
const IssuePriority = [CriticalPriority, HighPriority, NormalPriority, MinorPriority, Dismissed];

const OriginRegression = "Regresion";
const OriginSprint = "Tarjeta";
const OriginReleaseDay = "RELEASE DAY";
const OriginTest = "API test";
const OriginBiz = "BIZ";
const Origin = [OriginSprint, OriginBiz, OriginReleaseDay, OriginTest, OriginRegression];

const getOriginName = (origin) => {
    if (origin === OriginRegression) return "Regression";
    if (origin === OriginSprint) return "Sprint task";
    if (origin === OriginReleaseDay) return "Release Day";
    if (origin === OriginTest) return "API test";
    if (origin === OriginBiz) return "BIZ";
    return origin;
};

const Teams = {
    Rocket: [12, 15],
    TV: [8, 11, 56],
    Mobile: [28, 41, 65],
    Network: [14, 16, 68, 69],
    UX: [22, 51, 53, 66],
    QA: [19, 46, 60, 67],
    ["R&D"]: [3, 4],
    Devops: [2, 27, 30, 34, 72, 90],
    Technical: [36, 54, 73, 91],
    Boss: [62, 64],
    Datacenter: [61, 71],
    Marketing: [42],
    Account: [63, 52],
    Out: [17, 31, 49, 50, 55, 57, 33],
};

const CommonNames = ["juan", "santiago", "sara", "carlos", "eduardo", "eva", "jorge", "daniel"];

const lastSprintStart = new Date("2025-01-16T00:00:00Z");

const Gitlab = () => {
    const { load: loadIssues, data: openIssues, loading: loadingIssues, error: errorIssues } = useGitlabAPI("issues");
    const { load: loadUsers, data: users, loading: loadingUsers, error: errorUsers } = useGitlabAPI("members/all");
    const {
        load: loadClosedIssues,
        data: closed,
        loading: loadingClosedIssues,
        error: errorClosedIssues,
    } = useGitlabAPI("issues");

    const [dateRange, setDateRange] = useState({ from: null, to: null });

    const [activeTab, setActiveTab] = useState("stats");
    const [activeTeam, setActiveTeam] = useState("Rocket");

    useEffect(() => {
        loadUsers({ state: "active" });
    }, []);

    useEffect(() => {
        loadIssues({ state: "opened", type: "ISSUE" });
        loadClosedIssues({
            state: "closed",
            type: "ISSUE",
            order_by: activeTab === "closed" ? "closed_at" : "updated_at",
            sort: "desc",
            updated_after: lastSprintStart,
        });
    }, []);

    const loading = loadingIssues || loadingUsers || loadingClosedIssues;
    const error = errorIssues || errorUsers || errorClosedIssues;

    const closedIssues = closed?.filter((issue) => lastSprintStart <= new Date(issue.closed_at));

    const allIssues = [...(openIssues || []), ...(closedIssues || [])];

    const currentIssues = activeTab === "open" ? openIssues : activeTab === "closed" ? closedIssues : allIssues;

    const filteredIssues =
        dateRange?.from || dateRange?.to
            ? currentIssues?.filter((issue) => {
                  const date = new Date(issue.created_at);
                  const from = dateRange?.from ? new Date(dateRange?.from) : null;
                  const to = dateRange?.to ? new Date(dateRange?.to) : null;
                  return (!from || date >= from) && (!to || date <= to);
              })
            : currentIssues;

    const userOptions = users?.length
        ? users
              .map((u) => {
                  const team = getTeam(u.id);
                  if (team === "Out") return null;
                  return {
                      value: Number(u.id),
                      label: <DisplayUser id={u.id} name={u.name} />,
                      alt: u.name,
                      selectedLabel: u.name,
                  };
              })
              .filter((u) => u)
              .sort((a, b) => a.alt.localeCompare(b.alt))
              //Group by team
              .reduce((acc, u) => {
                  const team = getTeam(u.value);
                  if (!acc.find((t) => t.id === team)) {
                      acc.push({
                          id: team,
                          label: <DisplayTeam id={team} alt={team} />,
                          options: [],
                      });
                  }
                  acc.find((t) => t.id === team).options.push(u);
                  return acc;
              }, [])
        : [];

    const dateRangeInfo =
        dateRange?.from && dateRange?.to
            ? `From ${new Date(dateRange.from).toLocaleDateString(LOCALE)} to ${new Date(
                  dateRange.to
              ).toLocaleDateString(LOCALE)}`
            : dateRange?.from
            ? `From ${new Date(dateRange.from).toLocaleDateString(LOCALE)}`
            : dateRange?.to
            ? `To ${new Date(dateRange.to).toLocaleDateString(LOCALE)}`
            : "All time";

    const openByTeam = (team) => (i) => {
        const isTeam = i?.assignees?.some((a) => team.includes(a.id));
        if (team === Teams.Rocket || team === Teams.Network) {
            const isCasco = i?.assignees?.some((a) => a.id === 68);
            if (team === Teams.Rocket) {
                return isTeam || isCasco;
            } else {
                return isTeam && !isCasco;
            }
        }
        return isTeam;
    };

    const closedByTeam = (team) => (i) => {
        const isTeam = team.includes(i?.closed_by?.id);
        if (team === Teams.Rocket || team === Teams.Network) {
            const isCasco = i?.closed_by?.id === 68;
            if (team === Teams.Rocket) {
                return isTeam || isCasco;
            } else {
                return isTeam && !isCasco;
            }
        }
        return isTeam;
    };

    const filterAddedInSprint = (i) => {
        const date = new Date(i.created_at);
        return date >= lastSprintStart;
    };
    const statsAdded = allIssues?.filter(openByTeam(Teams[activeTeam])).filter(filterAddedInSprint).length;
    const statsClosed = closedIssues?.filter(closedByTeam(Teams[activeTeam])).filter(filterAddedInSprint).length;

    const filterTasks = (i) => {
        const currentSprint = filterAddedInSprint(i);
        const isSprintTask = i.labels.includes(OriginSprint);
        return currentSprint && isSprintTask;
    };
    const statsAddedTasks = allIssues?.filter(openByTeam(Teams[activeTeam])).filter(filterTasks).length;
    const statsClosedTasks = closedIssues?.filter(closedByTeam(Teams[activeTeam])).filter(filterTasks).length;

    const filterOthers = (i) => {
        const currentSprint = filterAddedInSprint(i);
        const isRegression = !i.labels.includes(OriginSprint);
        return currentSprint && isRegression;
    };
    const statsAddedOthers = allIssues?.filter(openByTeam(Teams[activeTeam])).filter(filterOthers).length;
    const statsClosedOthers = closedIssues?.filter(closedByTeam(Teams[activeTeam])).filter(filterOthers).length;

    return (
        <>
            <Header title={<Icon type="fab fa-gitlab" text="Gitlab issues" size={1.7} />} />
            <div className="p-10">
                {error ? <ErrorInfo useDefault={false}>{error}</ErrorInfo> : null}

                <div className="flex items-center space-x-2 pb-5">
                    <Button
                        id="all-issues"
                        onClick={() => setActiveTab(null)}
                        design={activeTab ? "blue-outline" : "blue"}
                    >
                        All issues
                    </Button>

                    <Button
                        id="open-issues"
                        onClick={() => setActiveTab("open")}
                        design={activeTab === "open" ? "blue" : "blue-outline"}
                    >
                        <div>Open issues</div>
                        <div className="text-xs pt-1">({dateRangeInfo || "All time"})</div>
                    </Button>
                    <Button
                        id="closed-issues"
                        onClick={() => setActiveTab("closed")}
                        design={activeTab === "closed" ? "blue" : "blue-outline"}
                    >
                        <div>Closed issues</div>
                        <div className="text-xs pt-1">(from {lastSprintStart.toLocaleDateString(LOCALE)})</div>
                    </Button>
                    <Button
                        id="stats"
                        onClick={() => setActiveTab("stats")}
                        design={activeTab === "stats" ? "blue" : "blue-outline"}
                    >
                        Stats
                    </Button>
                </div>

                {activeTab === "stats" ? (
                    <>
                        <div className="flex items-center space-x-2 pb-5">
                            {["Rocket", "TV", "Mobile", "Network"].map((team) => (
                                <Button
                                    id="team"
                                    key={team}
                                    onClick={() => setActiveTeam(team)}
                                    design={activeTeam === team ? "blue" : "blue-outline"}
                                >
                                    <DisplayTeam id={team} />
                                </Button>
                            ))}
                        </div>
                        <Table
                            id="stats"
                            key={activeTeam}
                            className="main-container"
                            loading={loading}
                            cols={["concept", "count", "closed"]}
                            rows={[
                                {
                                    concept: "Open issues",
                                    count: openIssues?.filter(openByTeam(Teams[activeTeam])).length,
                                },
                                {
                                    concept: "Issues closed in current sprint",
                                    count: closedIssues?.filter(closedByTeam(Teams[activeTeam])).length,
                                },
                                {
                                    concept: "Issues added in current sprint",
                                    count: statsAdded,
                                    closed: (
                                        <>
                                            {`${statsClosed} closed`} <br />
                                            {`${statsAdded - statsClosed} still open`}
                                        </>
                                    ),
                                },
                                {
                                    concept: "Issues added in current sprint (Sprint tasks)",
                                    count: statsAddedTasks,
                                    closed: (
                                        <>
                                            {`${statsClosedTasks} closed`} <br />
                                            {`${statsAddedTasks - statsClosedTasks} still open`}
                                        </>
                                    ),
                                },
                                {
                                    concept: "Issues added in current sprint (Others)",
                                    count: statsAddedOthers,
                                    closed: (
                                        <>
                                            {`${statsClosedOthers} closed`} <br />
                                            {`${statsAddedOthers - statsClosedOthers} still open`}
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </>
                ) : (
                    <Table
                        id="issues"
                        className="main-container"
                        showCount={true}
                        loading={loading}
                        search={true}
                        customFilters={true}
                        cols={[
                            "link",
                            "created",
                            ...(activeTab ? [activeTab === "closed" ? "closed" : "updated"] : ["closed", "updated"]),
                            "qa",
                            "milestone",
                            "priority",
                            "origin",
                            "dev",
                            "team",
                        ]}
                        sort={{ field: "updated", criteria: "desc" }}
                        header={{
                            link: { title: "ID", sortable: true },
                            created: { title: "Created", sortable: true },
                            closed: { title: "Closed", sortable: true },
                            updated: { title: "Updated", sortable: true },
                            milestone: { title: "Milestone", sortable: true },
                            priority: { title: "Priority", sortable: true },
                            origin: { title: "Origin", sortable: true },
                            qa: { title: "Reporter", sortable: true },
                            dev: { title: "Assigned", sortable: true },
                            team: { title: "Team", sortable: true },
                        }}
                        filters={[
                            {
                                id: "priority",
                                title: "Priority",
                                multiple: true,
                                options: IssuePriority.map((p) => ({
                                    value: p,
                                    label: <DisplayPriority id={p} />,
                                })),
                                onFilter: (values, row) =>
                                    values?.length ? row?.labels?.some((l) => values.includes(l)) : true,
                            },
                            {
                                id: "origin",
                                title: "Origin",
                                multiple: true,
                                options: Origin.map((o) => ({
                                    value: o,
                                    label: o,
                                })),
                                onFilter: (values, row) => (values?.length ? values.includes(row?.originID) : true),
                            },
                            {
                                id: "qa",
                                title: "Reporter",
                                multiple: true,
                                options: userOptions,
                                onFilter: (values, row) => (values?.length ? values.includes(row?.author?.id) : true),
                            },
                            {
                                id: "dev",
                                title: "Assigned",
                                multiple: true,
                                options: userOptions,
                                onFilter: (values, row) =>
                                    values?.length ? row?.assignees?.some((a) => values.includes(a.id)) : true,
                            },
                        ]}
                        customRangeSelector={{
                            value: { from: null, to: null },
                            onChange: setDateRange,
                            hideTimePicker: false,
                            hideFutureDates: true,
                        }}
                        rows={
                            filteredIssues?.length
                                ? filteredIssues.map((row) => {
                                      const foundPriority = IssuePriority.find((priority) =>
                                          row?.labels?.includes(priority)
                                      );
                                      const { priority, name: priorityName } = getPriorityInfo(foundPriority);
                                      const origin = Origin.find((o) => row?.labels?.includes(o));

                                      const team = getTeam(row?.assignees?.[0]?.id);

                                      return {
                                          ...row,
                                          link: (
                                              <a
                                                  href={`https://gitlab.zafiro.link/zafiro/qa/-/issues/${row?.iid}`}
                                                  alt={row?.iid}
                                                  target="_blank"
                                                  className="text-zafiro-600 hover:text-zafiro-300"
                                                  data-tip={row?.title}
                                                  data-for="default-tooltip"
                                              >
                                                  {row?.iid}
                                              </a>
                                          ),
                                          created: row.created_at ? <DisplayDate date={row.created_at} /> : null,
                                          updated: row.updated_at ? <DisplayDate date={row.updated_at} /> : null,
                                          closed: row.closed_at ? <DisplayDate date={row.closed_at} /> : null,
                                          milestone: (
                                              <a
                                                  href={row?.milestone?.web_url}
                                                  alt={row?.milestone?.title}
                                                  target="_blank"
                                                  className="text-zafiro-600 hover:text-zafiro-300"
                                              >
                                                  {row?.milestone?.title}
                                              </a>
                                          ),
                                          priorityID: priority,
                                          /*
                                        priority: (
                                            <Tag
                                                color={classNames({
                                                    "bg-red-600": priority === 1,
                                                    "bg-red-400": priority === 2,
                                                    "bg-orange-500": priority === 3,
                                                    "bg-yellow-500": priority === 4,
                                                    "bg-gray-400": priority > 5,
                                                    "bg-black": !priority,
                                                })}
                                                className="text-xs p-1 whitespace-no-wrap"
                                                tooltip={foundPriority}
                                            >
                                                {priorityName}
                                            </Tag>
                                        ),
                                        */
                                          priority: (
                                              <DisplayPriority alt={`${priority} ${priorityName}`} id={foundPriority} />
                                          ),
                                          originID: origin,
                                          origin: origin ? (
                                              <DisplayOrigin alt={`${origin} ${getOriginName(origin)}`} id={origin} />
                                          ) : null,
                                          qa: (
                                              <DisplayUser
                                                  alt={row?.author?.name}
                                                  id={row?.author?.id}
                                                  name={row?.author?.name}
                                              />
                                          ),
                                          dev: (
                                              <div alt={row?.assignees?.[0]?.name}>
                                                  {row?.assignees?.length
                                                      ? row?.assignees?.map((a) => (
                                                            <DisplayUser key={a.id} id={a.id} name={a.name} />
                                                        ))
                                                      : null}
                                              </div>
                                          ),
                                          team: team ? <DisplayTeam alt={team} id={team} /> : null,
                                      };
                                  })
                                : []
                        }
                    />
                )}
            </div>
        </>
    );
};

const getTeam = (id) => {
    return Object.entries(Teams).find(([, members]) => members.includes(id))?.[0];
};

const getTeamColor = (team) => {
    if (team === "Rocket") return "orange-500";
    if (team === "TV") return "blue-300";
    if (team === "Mobile") return "green-600";
    if (team === "Network") return "red-600";
    if (team === "UX") return "purple-600";
    if (team === "QA") return "black";
    return "gray-700";
};

const DisplayTeam = ({ id }) => {
    const color = getTeamColor(id);
    return (
        <Tag className={`text-xs px-1 leading-5 text-center text-white `} color={`bg-${color}`}>
            {id}
        </Tag>
    );
};

const DisplayUser = ({ id, name }) => {
    const firstName = name?.split(" ")[0];
    // Exception for user with id 8 (Juan Lavilla) because he has no second name defined in Gitlab
    const secondNameInitial = name?.split(" ")[1]?.[0] || (id === 8 ? "L" : null);
    const displayName = `${firstName}${
        CommonNames.includes(secondNameInitial && firstName?.toLowerCase()) ? " " + secondNameInitial : ""
    }`;
    const color = getTeamColor(getTeam(id));
    return (
        <div
            className={`flex items-center space-x-1 text-sm rounded capitalize whitespace-no-wrap text-${color}`}
            data-tip={name}
            data-for="default-tooltip"
        >
            <Icon type="far fa-user" size={0.75} className="leading-none" />
            <div className="leading-none">
                {id} - {displayName}
            </div>
        </div>
    );
};

const DisplayOrigin = ({ id }) => {
    const origin = Origin.find((o) => o === id);
    const originName = getOriginName(origin);
    return (
        <Tag
            className="text-xs p-1 whitespace-no-wrap"
            color={classNames({
                "bg-red-600": origin === OriginSprint,
                "bg-red-400": origin === OriginReleaseDay,
                "bg-orange-500": origin === OriginRegression,
                "bg-yellow-500": origin === OriginTest,
                "bg-gray-800": origin === OriginBiz,
            })}
        >
            {originName}
        </Tag>
    );
};

const getPriorityInfo = (id) => {
    const priority = IssuePriority.indexOf(id) + 1;
    const name = id?.match(/^\d?\+*\s*-*\s*(.+)/)?.[1] || id;
    return { priority, name };
};

const DisplayPriority = ({ id: foundPriority }) => {
    const { priority, name: priorityName } = getPriorityInfo(foundPriority);
    return (
        <Tag
            color={classNames({
                "bg-red-600": priority === 1,
                "bg-red-400": priority === 2,
                "bg-orange-500": priority === 3,
                "bg-yellow-500": priority === 4,
                "bg-gray-600": priority >= 5,
                "bg-black": !priority,
            })}
            className="text-xs p-1 whitespace-no-wrap"
            tooltip={foundPriority}
        >
            {priorityName || priority || foundPriority}
        </Tag>
    );
};

const DisplayDate = ({ date }) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;

    const todayDay = new Date();
    todayDay.setHours(0, 0, 0, 0);
    const currentDay = new Date(dateObj);
    currentDay.setHours(0, 0, 0, 0);
    const daysAgo = (todayDay - currentDay) / 86400000;

    const isToday = daysAgo < 1;
    const isYesterday = !isToday && daysAgo < 2;

    const tooltip = {
        "data-tip": new Date(date).toLocaleString(LOCALE),
        "data-for": "default-tooltip",
    };

    if (isToday) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Hoy <small className="text-sm text-gray-700">({time})</small>
            </div>
        );
    }

    if (isYesterday) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Ayer <small className="text-sm text-gray-700">({time})</small>
            </div>
        );
    }

    if (daysAgo <= 15) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Hace {daysAgo} días
            </div>
        );
    }

    return <div {...tooltip}>{new Date(date).toLocaleDateString(LOCALE)}</div>;
};

const useGitlabAPI = (endpoint) => {
    const [params, setParams] = useState(null);
    const [data, setData] = useState(null);
    const [count, setCount] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadAll = async (params) => {
        setLoading(true);
        setData(null);
        setError(null);
        setParams(params);
        setNextPage(1);
    };

    const load = async (params, page) => {
        const cleanParams = Object.fromEntries(
            Object.entries({
                page,
                per_page: 100,
                ...params,
            }).filter(([_, v]) => v !== undefined)
        );
        const urlParams = new URLSearchParams(cleanParams);
        const urlParamsString = urlParams?.size ? `?${urlParams.toString()}` : "";
        try {
            const response = await fetch(`${API_URL}/projects/${PROJECT_QA}/${endpoint}${urlParamsString}`, {
                headers: {
                    "Content-Type": "application/json",
                    "PRIVATE-TOKEN": TOKEN,
                },
            });
            setCount(Number(response.headers.get("X-Total")));
            if (params?.page) {
                // if page is defined, it means we not continue loading the next page
                setNextPage(null);
            } else {
                setNextPage(Number(response.headers.get("X-Next-Page")));
            }
            const json = await response.json();
            if (!response.ok) {
                throw new Error(json?.error || `${response.status}: ${response.statusText}`);
            }
            setError(null);
            setData((prevData) => (prevData ? [...prevData, ...json] : json));
        } catch (err) {
            setError(err);
            setData(null);
            setCount(null);
            setNextPage(null);
        }
    };

    useEffect(() => {
        if (nextPage) {
            load(params, nextPage);
        } else {
            setLoading(false);
        }
    }, [nextPage]);

    return {
        load: loadAll,
        data,
        error,
        count: count ?? data?.length,
        loading,
    };
};

export default Gitlab;
