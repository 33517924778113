import React from "react";
import { UPCOMING_STATE } from "components/Section/Customers/Guest/constants";
import {
    CheckInAction,
    DeleteUpcomingCheckIn,
    ResendCheckIn,
} from "components/Section/Customers/Guest/GuestListActions";

export const formatDate = (dateTimeString) => {
    if (!dateTimeString) return "-";
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const extractRoomsList = (data) => {
    let roomArray = [];
    // eslint-disable-next-line
    data?.results?.map((item) =>
        item?.rooms?.map((room) =>
            roomArray?.filter((r) => r.id === room.roomID).length === 0
                ? roomArray.push({
                      id: room.roomID,
                      value: room.roomID,
                      label: room.number,
                  })
                : null
        )
    );

    roomArray.sort(function (a, b) {
        return a.value - b.value;
    });

    return roomArray;
};

export const extractGroupList = (data) => {
    let groupsArray = [];
    // eslint-disable-next-line
    data?.results?.map((item) =>
        item?.guestGroups?.map((group) =>
            groupsArray?.filter((r) => r.id === group.id).length === 0
                ? groupsArray.push({
                      id: group.id,
                      value: group.id,
                      label: group.name,
                  })
                : null
        )
    );
    groupsArray.sort(function (a, b) {
        return a.value - b.value;
    });
    return groupsArray;
};

export const getState = (state, t) => {
    switch (state) {
        case UPCOMING_STATE.validated:
            return <p className="text-green-500">{t("validated")}</p>;
        case UPCOMING_STATE.pendingCompletion:
            return <p className="text-gray-500">{t("pending_completion")}</p>;
        case UPCOMING_STATE.pendingModification:
            return <p className="text-gray-500">{t("pending_modification")}</p>;
        case UPCOMING_STATE.pendingValidation:
            return <p className="text-yellow-500">{t("pending_validation")}</p>;
        default:
            return "Activo";
    }
};

export const getUpcomingActions = (guest, t, open, refetch) => {
    const { id, code, state } = guest;
    const actions = [];

    const isFutureReservation = () => {
        const reservationDate = new Date(guest?.reservationDate);
        reservationDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return reservationDate > today;
    };

    const isAFutureDate = isFutureReservation();

    actions.push({
        id: "check-stay",
        label: t("check-stay-verify-information"),
        onClick: () => (window.location.href = `#/customers/guests/guest-list/reservation-details/${id}`),
    });

    if (state === UPCOMING_STATE.validated) {
        actions.push({
            id: "check-in",
            label: t("Check-in"),
            disabled: isAFutureDate,
            tooltip: t("available-on", { date: guest.arrivalDate }),
            onClick: () => open(<CheckInAction guest={guest} refetch={refetch} />),
        });
    }

    if (state === UPCOMING_STATE.pendingCompletion) {
        actions.push({
            id: "resend-pre-check-in",
            label: t("resend-pre-check-in"),
            onClick: () => open(<ResendCheckIn id={id} code={code} />),
        });
    }

    actions.push({
        id: "delete",
        label: t("delete"),
        onClick: () => open(<DeleteUpcomingCheckIn id={id} code={code} refetch={refetch} />),
    });

    return actions;
};
